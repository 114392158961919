import { useState, createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

const ClientsContext = createContext();

export const useClientsContext = () => useContext(ClientsContext);
export const L_T_T = 'lastTestTime';

export default function ClientsProvider({ children }) {
  const clients = useSelector((state) => state.clients);

  const [searchStr, setSearchStr] = useState('');
  const [status, setStatus] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    attr: L_T_T,
    direction: false, // true: up, false: down
  });
  const [filteredClients, setFxClients] = useState([{ notLoaded: true }]);

  const numAllClients = useMemo(() => Object.keys(clients).length, [clients]);

  // TODO: there's likely a way to calculate this more efficiently in my
  // clients store -- get number when first loaded and update that number each
  // time a client is deleted and each time refreshed to get new tests
  const numTestResultsAllClients = useMemo(() => {
    let num = 0;
    Object.values(clients).forEach((c) => {
      Object.values(c.tests).forEach((t) => (num += Object.keys(t).length));
    });
    return num;
  }, [clients]);

  return (
    <ClientsContext.Provider
      value={{
        searchStr,
        setSearchStr,
        status,
        setStatus,
        sortConfig,
        setSortConfig,
        filteredClients,
        setFxClients,
        numAllClients,
        numTestResultsAllClients,
      }}
    >
      {children}
    </ClientsContext.Provider>
  );
}
