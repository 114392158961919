import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from './App';
import ModalProvider from './context/Modal';
import PaymentsProvider from './context/Payments';
import ClientsProvider from './context/Clients';
import NavProvider from './context/Nav';
import configureStore from './store/index';
import * as sessionActions from './store/user';
import './index.css';

// other
import isProduction from './services/isProduction';

// set up store
const store = configureStore();

// Gives me access to store & session actions in browser console on the window
// object. Example, type window.store.getState() in the browswer console.
if (!isProduction()) {
  window.store = store;
  window.sessionActions = sessionActions;
}

const root = createRoot(document.getElementById('root'));

root.render(
  <StrictMode>
    <Provider store={store}>
      <ClientsProvider>
        <ModalProvider>
          <PaymentsProvider>
            <NavProvider>
              <App />
            </NavProvider>
          </PaymentsProvider>
        </ModalProvider>
      </ClientsProvider>
    </Provider>
  </StrictMode>
);
