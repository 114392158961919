import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

// import reducers
import userReducer from './user';
import clientReducer from './clients';
import customerReducer from './customer';

// import other
import isProduction from '../services/isProduction';

const combineReducer = combineReducers({
  user: userReducer,
  customer: customerReducer,
  clients: clientReducer,
});

// changed reducer above from "rootReducer" to "combineReducer", and added
// this new "rootReducer" to remove all data from store when user logs out
const rootReducer = (state, action) => {
  if (action.type === 'session/REMOVE_USER') {
    state = undefined;
  }

  return combineReducer(state, action);
};

let enhancer;

if (isProduction()) {
  enhancer = applyMiddleware(thunk);
} else {
  const logger = require('redux-logger').default; // eslint-disable-line
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  enhancer = composeEnhancers(applyMiddleware(thunk, logger));
}

const configureStore = (preLoadedState) => {
  return createStore(rootReducer, preLoadedState, enhancer);
};

export default configureStore;
