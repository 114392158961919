import constants from 'constants';
import './styles.css';

export default function LoadingNotFoundInvalid({ message, subMessage }) {
  if (!message) message = `Loading ${constants.APP_NAME}...`;
  return (
    <div className='load-not-found-invalid'>
      <h1>{message}</h1>
      {subMessage && <h3>{subMessage}</h3>}
    </div>
  );
}
