import { useNavContext } from '../../../context/Nav';

export default function BarsIcon() {
  const { showDropdown, toggleDropdown } = useNavContext();
  const style = {
    filter: showDropdown
      ? 'var(--tertiary-color-via-filter)'
      : 'var(--primary-color-via-filter)',
    height: '1.5rem',
    cursor: 'pointer',
    transition: 'filter 0.5s',
  };

  return (
    <img
      src='/bars-solid.svg'
      alt='Menu bars'
      style={style}
      onClick={toggleDropdown}
    />
  );
}
