import HeaderLogo from './HeaderLogo';
import BarsIcon from './BarsIcon';
import NavBarLinks from '../general/NavBarLinks';
import { useWindowWidth } from '../../../services/useWindowWidth';

export default function Header({ hideNavigation }) {
  const width = useWindowWidth();
  return (
    <header className='site-header'>
      <HeaderLogo />
      <nav className='nav'>
        {!hideNavigation && (
          <ul className='nav__items'>
            {width > 800 && <NavBarLinks />}
            {width <= 800 && <BarsIcon />}
          </ul>
        )}
      </nav>
    </header>
  );
}
