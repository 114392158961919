import { useState, useContext, createContext } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../store/user';
import { usePaymentsContext } from './Payments';
import { useWindowWidth } from '../services/useWindowWidth';
import { useWindowHeight } from '../services/useWindowHeight';

const NavContext = createContext();
export const useNavContext = () => useContext(NavContext);

export default function NavProvider({ children }) {
  const dispatch = useDispatch();
  const { resetPaymentsContext } = usePaymentsContext();

  const [showDropdown, setShowDropdown] = useState(false);
  const [fetchingUser, setFetchingUser] = useState(true);

  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };

  const handleLogout = () => {
    const sCB = () => {
      // context reset has to happen after user is removed, otherwise if user
      // logs out while on '/account' the useEffect in 'customer.js' will just
      // be triggered and refetch the client info to put into context.
      resetPaymentsContext();
      setShowDropdown(false);
    };
    dispatch(logoutUser(sCB));
  };

  // hide navbar when srolling on small screens
  const width = useWindowWidth();
  const height = useWindowHeight();
  let oldYOffset = window.pageYOffset;
  window.onscroll = function () {
    // return early on bigger screens -- to just let nav bar always stay there
    if (width > 800 && height > 500) return;

    // when scrolling at all, hide the dropdown, and then clear the text again
    setShowDropdown(false);

    // 0 on scroll up to hide, -4 on scroll down to show (corresponds to height)
    document.getElementById('head-drpdn-cont').style.top =
      oldYOffset > window.pageYOffset ? '0' : '-4rem';

    oldYOffset = window.pageYOffset;
  };

  return (
    <NavContext.Provider
      value={{
        showDropdown,
        toggleDropdown,
        handleLogout,
        fetchingUser,
        setFetchingUser,
      }}
    >
      {children}
    </NavContext.Provider>
  );
}
