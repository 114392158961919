import constants from '../constants';
import axios from 'axios';

const axios_ = axios.create({
  withCredentials: true,
  xsrfHeaderName: constants.CSRF_HEADER_NAME,
  xsrfCookieName: constants.CSRF_COOKIE_NAME,
});

// // Was previously doing this, but didn't want to globally change axios
// axios.defaults.withCredentials = true;
// axios.defaults.xsrfHeaderName = constants.CSRF_HEADER_NAME;
// axios.defaults.xsrfCookieName = constants.CSRF_COOKIE_NAME;

/**
 *
 * @param {string} method - request method
 * @param {string} url - url of api endpoint
 * @param {{[key]: string: any}} body - body of the request
 * @param {(data, res) => void} sCB - success callback w/ args passed: `res.data` and `res`
 * @param {(errors: [], res) => void} fCB - failure callback w/ args passed: `res.errors` and `res`
 * @returns {Promise<{[key]: string: any}>} `res.data` object or `{errors: [...]}`
 */
export const axiosWithCBs = async (method, url, body, sCB, fCB) => {
  try {
    const res = await axios_[method](constants.API + url, body);
    const { data } = res;
    sCB(data, res);
    return data;
  } catch (error) {
    const { errors } = error.response.data;
    fCB(errors, error.response);
    return { errors };
  }
};

const myAxios = {
  async get(url, { sCB = () => {}, fCB = () => {} } = {}) {
    return await axiosWithCBs('get', url, {}, sCB, fCB);
  },
  async post(url, body, { sCB = () => {}, fCB = () => {} } = {}) {
    return await axiosWithCBs('post', url, body, sCB, fCB);
  },
  async patch(url, body, { sCB = () => {}, fCB = () => {} } = {}) {
    return await axiosWithCBs('patch', url, body, sCB, fCB);
  },
  async put(url, body, { sCB = () => {}, fCB = () => {} } = {}) {
    return await axiosWithCBs('put', url, body, sCB, fCB);
  },
  async delete(url, { sCB = () => {}, fCB = () => {} } = {}) {
    return await axiosWithCBs('delete', url, {}, sCB, fCB);
  },
};

export default myAxios;

// =============================================================================
// =============================================================================
/**
 * NOTE: I stopped using fetch b/c it couldn't set cookies in live environment.
 * I tried so many things, including getting the headers the same as axios makes
 * them and still couldn't work... It's maybe b/c subdomains are different
 * between BE (api.tryday.co) and FE (www.tryday.co) -- you can see my notes at
 * the very bottom trying to get it to work.
 *
 * everything below here is what I was using before
 */
// =============================================================================
// =============================================================================

// const getCookie = (name) => {
//   if (!document.cookie) return null;

//   const cookie = document.cookie
//     .split(';')
//     .map((c) => c.trim())
//     .find((c) => c.startsWith(name + '='));

//   if (!cookie) return null;

//   return decodeURIComponent(cookie.split('=')[1]);
// };

// /**
//  * Utility function for sending requests to my backend
//  *
//  * @param {str} resource - path to send request to
//  * @param {obj} init - object to include things such as method and body.
//  * @param {bool} appJson - whether content type should be 'application/json' (defaults to `true`)
//  * @returns - response as a promise
//  */
// const myFetch = async (resource, init = {}, appJson = true) => {
//   // add csrf
//   const csrfToken = getCookie(constants.CSRF_COOKIE_NAME);
//   init.headers = {
//     [constants.CSRF_HEADER_NAME]: csrfToken,
//     // see below for description of mode and credentials
//     mode: 'cors',
//     credentials: 'include',
//   };
//   // set content type
//   if (appJson && ['POST', 'PUT', 'PATCH'].includes(init.method)) {
//     init.headers['Content-Type'] = 'application/json';
//   }

//   if ('body' in init) {
//     init['body'] = JSON.stringify(init['body']);
//   }

//   return fetch(constants.API + resource, init);
// };

// //
// // Cross Origin: allows restricted resources on a web page to be requested from
// // another domain outside the domain from which the first resource was served
// //
// // Because requests to my live backend are cross-origin:
// //
// // ** mode: 'cors' **
// // Allows cross-origin requests
// //
// // ** credentials: 'include' **
// // Tells browsers to include credentials (cookies, authorization headers, or TLS
// // client certificates) in both same- and cross-origin requests, and always use
// // any credentials sent back in responses.
// //
