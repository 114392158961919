import { setCustomerAC } from '../customer';
import { setUserAC } from '../user';

const setUserAndCustomerAC = (userWCustomer) => (dispatch) => {
  const { customer, ...user } = userWCustomer;
  dispatch(setUserAC(user));
  dispatch(setCustomerAC(customer));
};

export default setUserAndCustomerAC;
