import { axiosWithCBs } from '../../services/myAxios';

/**
 *
 * @param {string} method - request method
 * @param {string} url - url of api endpoint
 * @param {() => void | { [key: string]: string }} aOrAC - the action (object, dispatched as is) or action creator (function, will be passed args: `res.data` and `res`, then dispatched)
 * @param {(data, res) => void} sCB - success callback w/ args passed: `res.data` and `res`
 * @param {(errors, res) => void} fCB - failure callback w/ args passed: `res.errors` and `res`
 * @param {{ [key: string]: string }} body - body of the request
 * @returns {{ [key: string]: string }} `res.data` object or `{errors: [...]}`
 */
function dispatchCreator(method, url, aOrAC, sCB, fCB, body = {}) {
  return (dispatch) => {
    const _sCB = (data, res) => {
      const action = typeof aOrAC === 'function' ? aOrAC(data, res) : aOrAC;
      dispatch(action);
      sCB(data, res);
    };
    return axiosWithCBs(method, url, body, _sCB, fCB);
  };
}

/**
 * Object with methods to create Thunks
 *
 * The corresponding request will be sent, followed by the (1) dispatch and then
 * success callback, otherwise (2) the failure callback
 */
const myThunk = {
  get(url, actionOrCreator, { sCB = () => {}, fCB = () => {} } = {}) {
    return dispatchCreator('get', url, actionOrCreator, sCB, fCB);
  },
  post(url, body, actionOrCreator, { sCB = () => {}, fCB = () => {} } = {}) {
    return dispatchCreator('post', url, actionOrCreator, sCB, fCB, body);
  },
  patch(url, body, actionOrCreator, { sCB = () => {}, fCB = () => {} } = {}) {
    return dispatchCreator('patch', url, actionOrCreator, sCB, fCB, body);
  },
  put(url, body, actionOrCreator, { sCB = () => {}, fCB = () => {} } = {}) {
    return dispatchCreator('put', url, actionOrCreator, sCB, fCB, body);
  },
  delete(url, actionOrCreator, { sCB = () => {}, fCB = () => {} } = {}) {
    return dispatchCreator('delete', url, actionOrCreator, sCB, fCB);
  },
};

export default myThunk;
