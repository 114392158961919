import sharedConfig from './shared_config.json';

/**
 * The value of NODE_ENV is set automatically to:
 * - development (when using npm start)
 * - test (when using npm test)
 * - production (when using npm build)
 * https://tacomanator.medium.com/environments-with-create-react-app-7b645312c09d
 */

export function isProd() {
  /* eslint-disable no-undef */
  return process.env.NODE_ENV === 'production';
  /* eslint-enable */
}

const DEV_STRIPE_PK =
  'pk_test_51IZ6D5JUL0dIO0rKUPAxSqGoudDDrzwOzpng5VrTsvShJc5ZjtU0Tz65iFKlMWlRLF4vuWmXBLb0fSzs3N9cpWui004mGJ9jcg';
const PROD_STRIPE_PK =
  'pk_live_51IZ6D5JUL0dIO0rKVLwxhdwefcaomw9ZGqrHJgY83evdp47BJaMOqy2fNgxspYoR8CaXbiWl3rsifqra6eCFsnfv008YK026Ri';
// ^^^ safe to have on frontend: https://stackoverflow.com/a/68878014

const constants = {
  STRIPE_PUBLISHABLE_KEY: isProd() ? PROD_STRIPE_PK : DEV_STRIPE_PK,

  CSRF_COOKIE_NAME: 'csrf_token',
  CSRF_HEADER_NAME: 'X-CSRFToken',

  APP_NAME: sharedConfig.APP_NAME,
  APP_URL: sharedConfig.APP_URL,
  API: isProd() ? 'https://api.tryday.co' : '',

  // Name format needed for getSubPrice below
  TIER_0_PRICE: 'Free',
  TIER_1_PRICE: 3.99,
  TIER_2_PRICE: 6.99,
  TIER_3_PRICE: 9.99,

  PAID_TIERS: sharedConfig.PAID_TIERS,

  // Name format needed for getSubName below
  TIER_0_NAME: 'Free',
  TIER_1_NAME: 'Small Caseload',
  TIER_2_NAME: 'Medium Caseload',
  TIER_3_NAME: 'Large Caseload',

  PAID_TESTS_NAME: 'paid',

  CHECK_SUB_DAYS_AFTER_BD: 8,

  TEXT_INPUT_MAX_LENGTH: sharedConfig.TEXT_INPUT_MAX_LENGTH,
};

export const api = {
  CLIENTS: '/clients/',
  EMAILS: '/emails/',
  FEEDBACK: '/feedback/',
  PAYMENTS: '/payments/',
  RESULTS: '/results/',
  SESSIONS: '/sessions/',
  USERS: '/users/',
};

export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
// from: https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression/8829363#8829363

/**
 * @param {number} subTier - integer corresponding to subscription tier
 * @returns corresponding subscription name
 */
export const getSubName = (subTier) => constants[`TIER_${subTier}_NAME`];

/**
 * @param {number} subTier - integer corresponding to subscription tier
 * @returns corresponding subscription price
 */
export const getSubPrice = (subTier) => constants[`TIER_${subTier}_PRICE`];

/**
 * @param {number} subTier - integer corresponding to subscription tier
 * @returns corresponding subscription name and price as a formatted string
 */
export const getSubNameAndPrice = (subTier) => {
  return getSubName(subTier) + ' - $' + getSubPrice(subTier);
};

/**
 * @param {number} subTier - integer corresponding to subscription tier
 * @returns corresponding max number or clients allowed
 */
export const getSubMaxNumClients = (subTier) => {
  return sharedConfig[`TIER_${subTier}_MAX_NUM_CLIENTS`];
};

/**
 * @param {number} subTier - integer corresponding to subscription tier
 * @returns corresponding subscription max number of tests allowed (`'infinity'` or number)
 */
export const getSubMaxNumTests = (subTier) => {
  let res = sharedConfig[`TIER_${subTier}_MAX_NUM_TESTS`];
  if (res === 'Infinity') res = 'Unlimited';
  return res;
};
export default constants;
