import { useNavigate } from 'react-router-dom';
import constants from '../../../../constants';
import { PATHS } from '../../../../routes';

export default function HeaderLogo() {
  const navigate = useNavigate();
  return (
    <div
      className='site-header__title primary-title'
      onClick={() => navigate(PATHS.HOME)}
    >
      <img
        src='/styled-name.svg'
        alt={`${constants.APP_NAME} logo`}
        style={{ height: '2.5rem' }}
      />
    </div>
  );
}
