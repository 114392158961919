import { useEffect, lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getUser } from 'store/user';
import { updateBillDateAndStatus } from 'store/customer';
import { getClients } from 'store/clients';
import NavBar from 'components/NavBar';
import Footer from 'components/Footer';
import LoadingNotFoundInvalid from 'components/LoadingNotFoundInvalid';
import datePlus from 'services/datePlus';
import constants from 'constants';
import { PATHS as P, MRoute, NavLogin } from 'routes';
import useOneRunEffect from 'services/useOneRunEffect';
import { useNavContext } from 'context/Nav';

const { CHECK_SUB_DAYS_AFTER_BD } = constants;

const Account = lazy(() => import('pages/Account'));
const About = lazy(() => import('pages/About'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'));
const Client = lazy(() => import('pages/Client'));
const ClientForm = lazy(() => import('pages/ClientForm'));
const Clients = lazy(() => import('pages/Clients'));
const FourOhFour = lazy(() => import('pages/FourOhFour'));
const Feedback = lazy(() => import('pages/Feedback'));
const FeedbackThankYou = lazy(() => import('pages/FeedbackThankYou'));
const JwtCheck = lazy(() => import('pages/JwtCheck'));
const LogIn = lazy(() => import('pages/LogIn'));
const Payments = lazy(() => import('pages/Payments'));
const SignUp = lazy(() => import('pages/SignUp'));
const SplashPage = lazy(() => import('pages/SplashPage'));
const Subscriptions = lazy(() => import('pages/Subscriptions'));
const TermsOfUse = lazy(() => import('pages/TermsOfUse'));
const TestDescriptions = lazy(() => import('pages/TestDescriptions'));
const TestForm = lazy(() => import('pages/TestForm'));

function App() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { isActive, nextBillDate, activeWillCx } =
    useSelector((state) => state.customer) || {};
  const { fetchingUser, setFetchingUser } = useNavContext();

  useOneRunEffect(
    () => {
      const sCB = () => {
        setFetchingUser(false);
        dispatch(getClients());
      };
      const fCB = () => setFetchingUser(false);
      dispatch(getUser(sCB, fCB));
    },
    [dispatch],
    !user
  );

  useEffect(() => {
    // trigger subscription check
    if (!fetchingUser && isActive) {
      // check right away after bill date if it's scheduled to cancel, otherwise
      // give 8 extra days too allow for 3 retries: https://dashboard.stripe.com/settings/billing/automatic
      // if all 3 fail, status in stripe will be cancelled and my backend will get that from the response.
      const checkBy = activeWillCx
        ? nextBillDate
        : datePlus(nextBillDate, { days: CHECK_SUB_DAYS_AFTER_BD });

      if (new Date() > checkBy) dispatch(updateBillDateAndStatus());
    }
  }, [dispatch, fetchingUser, isActive, nextBillDate, activeWillCx]);

  const auth = (element) =>
    fetchingUser ? <LoadingNotFoundInvalid /> : !user ? NavLogin : element;
  // "Loading" needed so refreshing doesn't cause auth elements to redirect to
  // home before user is returned

  return (
    <BrowserRouter>
      <NavBar />
      <div className='btwn-nav-footer'>
        <Suspense fallback={<LoadingNotFoundInvalid />}>
          <Routes>
            <Route element={<SplashPage />} path={P.HOME} />
            <Route element={<Subscriptions />} path={P.SUBSCRIPTIONS} />
            <Route element={<LogIn />} path={P.LOG_IN} />
            <Route element={<SignUp />} path={P.SIGN_UP} />
            <Route element={<TermsOfUse />} path={P.TERMS_OF_USE} />
            <Route element={<PrivacyPolicy />} path={P.PRIVACY_POLICY} />
            <Route element={<About />} path={P.ABOUT} />
            <Route element={auth(<Clients />)} path={P.CLIENTS} />
            <Route element={auth(<Client />)} path={P.CLIENTS_ID} />
            <Route element={auth(<Account />)} path={P.ACCOUNT} />
            <Route element={<TestDescriptions />} path={P.TESTS} />
            <Route element={<TestForm />} path={P.TAKE_TEST} />
            {MRoute(auth(<ClientForm />), P.CLIENTS_NEW, P.CLIENTS_ID_EDIT)}
            {MRoute(auth(<Payments />), P.PAY_CREATE, P.PAY_UPDATE)}
            {MRoute(auth(<Feedback />), P.UNSUB, P.DEACT)}
            <Route element={<FeedbackThankYou />} path={P.FEEDBACK_THX} />
            {MRoute(<Feedback />, P.FEEDBACK, P.CONTACT)}
            {MRoute(
              <JwtCheck />,
              P.VERIFY_ACCOUNT,
              P.LOGIN_ACCOUNT,
              P.VERIFY_NEW_EMAIL
            )}
            <Route element={<FourOhFour />} path={P.CATCH_ALL} />
          </Routes>
        </Suspense>
      </div>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
