import { useMemo } from 'react';
import { useWindowWidth } from 'services/useWindowWidth';
import Dropdown from './Dropdown';
import Header from './Header';
import { useLocation } from 'react-router-dom';
import { PATHS } from 'routes';

import './styles.css';

const NavBar = () => {
  const width = useWindowWidth();
  const { pathname } = useLocation();

  const hideNavigation = useMemo(() => {
    const subPathLength = PATHS.TAKE_TEST.split('/')[1].length + 2;
    // add 2 ^^^ to account for two of "/"
    return pathname.startsWith(PATHS.TAKE_TEST.slice(0, subPathLength));
  }, [pathname]);

  return (
    <div id='head-drpdn-cont' className='header-dropdown-container'>
      <Header hideNavigation={hideNavigation} />
      {!hideNavigation && width <= 800 && <Dropdown />}
    </div>
  );
};

export default NavBar;
