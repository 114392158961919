import A from 'components/AnchorRelUrl';
import './styles.css';
import { PATHS } from 'routes';

export default function Footer() {
  return (
    <>
      <div className='one1rem-ht' />
      <div className='footer'>
        <A text='About' path={PATHS.ABOUT} extraClassName='footer__text' />
        <p>|</p>
        <A text='Contact' path={PATHS.CONTACT} extraClassName='footer__text' />
        <p>|</p>
        <A
          text='Feedback'
          path={PATHS.FEEDBACK}
          extraClassName='footer__text'
        />
      </div>
    </>
  );
}
