import { useEffect, useState } from 'react';

export const useWindowHeight = () => {
  let [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const changeWindowSize = () => {
      setWindowHeight(window.innerHeight);
    };
    window.addEventListener('resize', changeWindowSize);
    return () => window.removeEventListener('resize', changeWindowSize);
  }, []);
  return windowHeight;
};
