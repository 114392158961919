import { useSelector } from 'react-redux';
import { useNavContext } from '../../context/Nav';
import NavBarLinks from './general/NavBarLinks';

export default function Dropdown() {
  const user = useSelector((state) => state.user);
  const { showDropdown, toggleDropdown } = useNavContext();

  const num = !showDropdown ? -1 : user ? -10.5 : -8;
  const style = { bottom: num + 'rem' };

  return (
    <>
      <ul id='dropdown' className='nav__dropdown' style={style}>
        <NavBarLinks />
      </ul>
      {showDropdown && (
        <div
          onClick={toggleDropdown}
          className='nav__dropdown__rest-of-page'
        ></div>
      )}
    </>
  );
}
