import { Route, Navigate } from 'react-router-dom';
import { PAYMENTS_CREATE, PAYMENTS_UPDATE } from './context/Payments';

export const PATHS = {
  ACCOUNT: '/account',
  ABOUT: '/about',
  PRIVACY_POLICY: '/privacy-policy',
  CATCH_ALL: '/*',
  CLIENTS: '/clients',
  CLIENTS_ID: '/clients/:clientId',
  CLIENTS_ID_EDIT: '/clients/:clientId/edit',
  CLIENTS_NEW: '/clients/new',
  CONTACT: '/contact',
  DEACT: '/deactivate',
  FEEDBACK: '/feedback',
  FEEDBACK_THX: '/feedback/thank-you',
  HOME: '/',
  LOG_IN: '/log-in',
  LOGIN_ACCOUNT: '/login-account', // not to be confused with LOG_IN above
  PAY_CREATE: `${PAYMENTS_CREATE}:subPageId`,
  PAY_UPDATE: `${PAYMENTS_UPDATE}:subPageId`,
  SIGN_UP: '/sign-up',
  SUBSCRIPTIONS: '/subscriptions',
  TAKE_TEST: '/test/:testCode/:identifier',
  TERMS_OF_USE: '/terms-of-use',
  TESTS: '/tests',
  UNSUB: '/unsubscribe',
  VERIFY_ACCOUNT: '/verify-account',
  VERIFY_NEW_EMAIL: '/verify-new-email',
};

/**
 * For using the same JSX element for **multiple** paths
 * @param {JSXElement} el
 * @param  {...string} paths
 * @returns `Route` elements
 */
export const MRoute = (el, ...paths) => {
  return paths.map((p) => <Route key={p} element={el} path={p} />);
};

export const NavLogin = <Navigate to={PATHS.LOG_IN} />;
