import { useSelector } from 'react-redux';
import { PATHS } from '../../../../routes';
import NavBarLink from './NavBarLink';
import { useNavContext } from '../../../../context/Nav';

export const LOGOUT_TEXT = 'Logout';

const linksLoggedIn = [
  { text: 'Clients', to: PATHS.CLIENTS },
  { text: 'Account', to: PATHS.ACCOUNT },
  { text: 'Tests', to: PATHS.TESTS },
  { text: LOGOUT_TEXT, to: PATHS.HOME },
];

const linksLoggedOut = [
  { text: 'Log In', to: PATHS.LOG_IN },
  { text: 'Sign Up', to: PATHS.SIGN_UP },
  { text: 'Tests', to: PATHS.TESTS },
];

const linksFetchingUser = [{ text: 'Loading...', to: '/' }];

export default function NavBarLinks() {
  const user = useSelector((state) => state.user);
  const { fetchingUser } = useNavContext();

  const links = fetchingUser
    ? linksFetchingUser
    : user
    ? linksLoggedIn
    : linksLoggedOut;

  return links.map((props) => <NavBarLink key={props.text} {...props} />);
}
