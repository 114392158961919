import {
  useState,
  useContext,
  createContext,
  useMemo,
  useCallback,
} from 'react';

const PaymentsContext = createContext();
export const usePaymentsContext = () => useContext(PaymentsContext);

export const PAYMENTS_CREATE = '/payments/';
export const PAYMENTS_UPDATE = '/payments/update/';

const LOADING = '(Loading...)';
// this will be displayed while stripe customer is being loaded
const defaultCustomer = {
  name: LOADING,
  address: {
    line1: '-',
    city: '-',
    state: '',
    postal_code: '-',
  },
  email: '-',
};

// when the state is set later, I'm just replacing this with an object with only
// one of these attributes as a key with a value of true. I have them all here
// as a template
const defaultPaymentConfig = {
  subscribe: false,
  reactivate: false,
  updateSubTier: false,
  updateCard: false,
  updateCustomer: false,
};

export default function PaymentsProvider({ children }) {
  const [stripeCstmrInfo, setStripeCstmrInfo] = useState(defaultCustomer);
  const [newCustomer, setNewCustomer] = useState('');
  const [newPayMthd, setNewPayMthd] = useState('');
  const [paymentURL, setPaymentURL] = useState('');
  const [newSubTier, setNewSubTier] = useState('');
  const [paymentConfig, setPaymentConfig] = useState(defaultPaymentConfig);

  const stripeCstmrLoaded = useMemo(
    () => stripeCstmrInfo.name !== LOADING && stripeCstmrInfo.email !== '-',
    [stripeCstmrInfo]
  );

  /**
   * Function to reset all context state on logout. This only matters if someone
   * has a subscription and then sets the context by visiting `/account`, then
   * logs out, then without refreshing the page logs into an account without a
   * subscription, then clicks 'Subscribe'. In that case, the previous users
   * account info will be autopopulated in the form.
   *
   * Also used (w/ `resetCustomer` as false) everytime the user navigates back
   * to `/account` to prepare for getting to any form.
   */
  const resetPaymentsContext = useCallback(
    (resetCustomer = true) => {
      if (resetCustomer) setStripeCstmrInfo(defaultCustomer);
      setNewCustomer('');
      setNewPayMthd('');
      setPaymentURL('');
      setNewSubTier('');
      setPaymentConfig(defaultPaymentConfig);
    },
    [setStripeCstmrInfo, setNewCustomer, setNewPayMthd, setPaymentURL]
  );

  return (
    <PaymentsContext.Provider
      value={{
        stripeCstmrInfo,
        setStripeCstmrInfo,
        newCustomer,
        setNewCustomer,
        newPayMthd,
        setNewPayMthd,
        paymentURL,
        setPaymentURL,
        stripeCstmrLoaded,
        resetPaymentsContext,
        newSubTier,
        setNewSubTier,
        paymentConfig,
        setPaymentConfig,
      }}
    >
      {children}
    </PaymentsContext.Provider>
  );
}
