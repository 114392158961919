import { useNavigate } from 'react-router-dom';

// use this for internal links
export default function AnchorRelUrl({ text, path, extraClassName }) {
  const navigate = useNavigate();

  let xCN = ' ';
  if (extraClassName) xCN += extraClassName;
  return (
    <a className={'clickable-link' + xCN} onClick={() => navigate(path)}>
      {text}
    </a>
  );
}
