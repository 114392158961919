import { NavLink } from 'react-router-dom';
import { useWindowWidth } from '../../../../services/useWindowWidth';
import { useNavContext } from '../../../../context/Nav';
import { LOGOUT_TEXT } from '.';

export default function NavBarLink({ text, to }) {
  const width = useWindowWidth();
  const { toggleDropdown, handleLogout } = useNavContext();

  const onClick = text !== LOGOUT_TEXT ? toggleDropdown : handleLogout;

  const navClass = width > 800 ? 'nav__item' : 'nav__dropdown__item';
  const navClassPlusActive = `${navClass} nav__item-active`;

  const className = (navData) =>
    navData.isActive && text !== LOGOUT_TEXT ? navClassPlusActive : navClass;

  return (
    <NavLink onClick={onClick} to={to} className={className}>
      {text}
    </NavLink>
  );
}
