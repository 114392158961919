/**
 * Takes in any number of date properties and returns a function that will take
 * in an object to go into the store and convert the corresponding date
 * properties into js date objects.
 *
 * @param  {...string} dateProperties
 * @returns (storeObj) => {`convert properties to date objects`}
 */
const createDateConverter =
  (...dateProperties) =>
  (storeObj) => {
    for (const p of dateProperties) storeObj[p] = new Date(storeObj[p]);
  };

export default createDateConverter;
