import { setUserAndCustomerAC } from './storeHelpers';
import { myThunk } from './storeHelpers';
import { api } from '../constants';

// Action Types
const SET = 'session/SET';
const UPDATE = 'session/UPDATE';
const REMOVE = 'session/REMOVE';

// Action Creators
export const setUserAC = (user) => ({ type: SET, user });
const editUserAC = (user) => ({ type: UPDATE, user });
const removeUserAC = () => ({ type: REMOVE });

// Thunks
export const signUpUser = (body, sCB, fCB) => {
  return myThunk.post(api.USERS, body, setUserAndCustomerAC, { sCB, fCB });
};

export const editUser = (body, sCB, fCB) => {
  return myThunk.put(api.USERS, body, editUserAC, { sCB, fCB });
};

export const updatePendingEmail = (body, sCB, fCB) => {
  const url = `${api.EMAILS}update-email`;
  return myThunk.post(url, body, editUserAC, { sCB, fCB });
};

export const logoutUser = (sCB) => {
  return myThunk.delete(api.SESSIONS, removeUserAC, { sCB }); // TODO: fCB?
};

export const getUser = (sCB, fCB) => {
  return myThunk.get(api.USERS, setUserAndCustomerAC, { sCB, fCB });
};

export const deleteUser = (sCB, fCB) => {
  return myThunk.delete(api.USERS, removeUserAC, { sCB, fCB });
};

// Reducer
const userReducer = (state = null, action) => {
  switch (action.type) {
    case SET:
    case UPDATE:
      return action.user;
    case REMOVE:
      return null;
    default:
      return state;
  }
};

export default userReducer;
