/**
 * Takes in any number of properties and returns a function that will take in an
 * object to go into the store and convert the corresponding properties into js
 * `Infinity` if they are the string `'Infinity'`
 *
 * @param  {...string} dateProperties
 * @returns (storeObj) => {`convert properties to Infinity`}
 */
const createInfinityConverter =
  (...dateProperties) =>
  (storeObj) => {
    for (const p of dateProperties) {
      if (storeObj[p] === 'Infinity') storeObj[p] = Infinity;
    }
  };

export default createInfinityConverter;
