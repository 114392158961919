import { useEffect, useRef } from 'react';

/**
 * Custom `useEffect` that will only run once each time the condition `runWhen`
 * is met (ie. it will only run more than once if the condition has become unmet
 * and then met again).
 *
 * If the `useEffect` only ever needs to run once, leave out `runWhen` arg.
 *
 * Note: If you have any early return conditionals inside of the `effect`
 * callback passed in, consider if it makes sense to instead include it in the
 * `runWhen` boolean.
 *
 * Note: this hook will not show lint when dependencies are wrong, so toggle to
 * a regular `useEffect` to check depency array.
 *
 * @param {React.EffectCallback} effect - Imperative function that can return a cleanup function
 * @param {React.DependencyList} deps - If present, effect will only activate if the values in the list change.
 * @param {boolean} runWhen - A `boolean` (defaults to `true`) indicating when to allow the one `useEffect` to start. Should be `false` as long as we don't want this to run yet, such as if other things are finishing first.
 */
const useOneRunEffect = (effect, deps = [], runWhen = true) => {
  const alreadyRan = useRef(false);

  useEffect(() => {
    if (!runWhen) alreadyRan.current = false;
  }, [runWhen]);

  useEffect(() => {
    if (runWhen && !alreadyRan.current) {
      alreadyRan.current = true;
      return effect();
    }
  }, [effect, runWhen, ...deps]); // eslint-disable-line react-hooks/exhaustive-deps
};

export default useOneRunEffect;
