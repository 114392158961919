import _ from 'lodash';
import {
  createDateConverter,
  createInfinityConverter,
  myThunk,
} from './storeHelpers';
import { api } from '../constants';
import myAxios from '../services/myAxios';

// Action Types
const SET_STRIPE_CUSTOMER_EXISTS = 'customer/SET_STRIPE_CUSTOMER_EXISTS';
const SET_CUSTOMER = 'customer/SET_CUSTOMER';
const UPDATE_CUSTOMER = 'customer/UPDATE_CUSTOMER';

// Action Creators
const setStripeCustomerExistsAC = () => ({ type: SET_STRIPE_CUSTOMER_EXISTS });
export const setCustomerAC = (customer) => ({ type: SET_CUSTOMER, customer });
const updateCustomerAC = (customer) => ({ type: UPDATE_CUSTOMER, customer });

// Thunks
const PAYMENTS_CUSTOMER = `${api.PAYMENTS}customer`;

export const createStripeCustomer = (customer, sCB, fCB) => {
  const action = setStripeCustomerExistsAC(); // doesn't need anything passed in so just create now
  return myThunk.post(PAYMENTS_CUSTOMER, customer, action, { sCB, fCB });
};

export const updateStripeCustomer = (customer, sCB, fCB) => {
  return myAxios.put(PAYMENTS_CUSTOMER, customer, { sCB, fCB });
};

// add payment method onto the customer, and get updated customer into redux store
// if there's already an active subscription, this updates the default payment method
export const addPyMthdCrtOrUpdtSub = (
  paymentConfig,
  newPayMthd,
  newSubTier,
  sCB,
  fCB
) => {
  const { subscribe, reactivate, updateSubTier, updateCard } = paymentConfig;

  let data = {};

  if (subscribe || updateSubTier) {
    data.subTier = newSubTier;
  }

  if (subscribe || reactivate || updateCard) {
    data.paymentMethodId = newPayMthd.id;
    data.brand = newPayMthd.card.brand;
    data.last4 = newPayMthd.card.last4;
    data.expMonth = newPayMthd.card.exp_month;
    data.expYear = newPayMthd.card.exp_year;
  }

  // _.pickBy returns an object of all the keys that correspond to a truthy
  // value, which should only ever be one => the selected key/one I need.
  const configKey = Object.keys(_.pickBy(paymentConfig))[0];

  const methodAndEndpath = {
    subscribe: ['post', 'subscription'],
    reactivate: ['post', 'reactivation'],
    updateSubTier: ['patch', 'sub-tier'],
    updateCard: ['patch', 'card'],
  };
  const [method, endpath] = methodAndEndpath[configKey];
  const url = api.PAYMENTS + endpath;
  return myThunk[method](url, data, updateCustomerAC, { sCB, fCB });
};

export const cancelSubscription = (sCB, fCB) => {
  const url = api.PAYMENTS + 'subscription';
  return myThunk.delete(url, updateCustomerAC, { sCB, fCB });
};

export const updateBillDateAndStatus = () => {
  const actionCreator = (customer, res) => {
    if (res.data.message === 'Nothing to update') return () => {};
    /**
     * I return {"message": "Nothing to update"} when nothing updated (should
     * not happen, but this prevents an infinite loop in `useEffect` where this
     * thunk is dispatched if somehow it's called and customer `nextBillDate`
     * isn't updated. Otherwise, updating the customer would keep triggering the
     * `useEffect` and pass the conditional to dispatch the thunk)
     */
    return updateCustomerAC(customer);
  };
  const url = api.PAYMENTS + 'bill-date-and-status';
  return myThunk.get(url, actionCreator);
  // TODO: fCB???
};

// TODO: sometimes nextBillDate is `null` which leads to a date of `1970-01-01T00:00:00.000Z`
// in the redux store. As of Feb 2024, nextBillDate is only used when the customer is
// active, so it's good for now, but this may cause problems in the future.
const dateConverter = createDateConverter('nextBillDate');
const infinityConverter = createInfinityConverter('maxNumTestResults');

// Reducer
const customerReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_STRIPE_CUSTOMER_EXISTS:
      return { ...state, stripeCustomerExists: true };
    case SET_CUSTOMER:
    case UPDATE_CUSTOMER:
      dateConverter(action.customer);
      infinityConverter(action.customer);
      return action.customer;
    default:
      return state;
  }
};

export default customerReducer;
