import _ from 'lodash';
import { createDateConverter } from './storeHelpers';
import { api } from '../constants';
import { myThunk } from './storeHelpers';

// Action types
const SET_ONE = '/clients/SET_ONE';
const SET_ALL = '/clients/SET_ALL';
const CREATE = '/clients/CREATE';
const UPDATE = '/clients/UPDATE';
const TOGGLE_TESTS_SEEN = '/clients/TOGGLE_TESTS_SEEN';
const RESET_LINKS = '/clients/RESET_LINKS';
const REMOVE_CLIENT = '/clients/REMOVE_CLIENT';
const REMOVE_TEST = '/clients/REMOVE_TEST';

// Action creators
const getClientAC = (client) => ({ type: SET_ONE, client });
const getClientsAC = (res) => ({ type: SET_ALL, clients: res.clients });
const createAC = (client) => ({ type: CREATE, client });
const updateAC = (client) => ({ type: UPDATE, client });
const toggleTestsSeenAC = (clientId, testIdsToToggle, testCode) => ({
  type: TOGGLE_TESTS_SEEN,
  clientId,
  testIdsToToggle,
  testCode,
});
const resetLinksAC = (client) => ({ type: RESET_LINKS, client });
const deleteClientAC = (res) => ({ type: REMOVE_CLIENT, clientId: res.id });
const deleteTestAC = (clientId, test) => ({
  type: REMOVE_TEST,
  clientId,
  test,
});

// Thunks
// gets a single client
export const getClient = (id) => myThunk.get(api.CLIENTS + id, getClientAC);

// gets all clients associated with logged-in user
export const getClients = () => {
  return myThunk.get(api.CLIENTS, getClientsAC);
  // TODO: fCB ???
};

// create is also used to update if clientId is passed in as second argument
export const createOrUpdateClient = (client, idToUpdate = null, sCB, fCB) => {
  const [method, url, actionCreator] = idToUpdate
    ? ['put', `${api.CLIENTS}${idToUpdate}`, updateAC]
    : ['post', api.CLIENTS, createAC];
  return myThunk[method](url, client, actionCreator, { sCB, fCB });
};

export const resetLinks = (id) => {
  return myThunk.put(`${api.CLIENTS}uuid/${id}`, {}, resetLinksAC);
  // fCB dealt with in modal from returned errors, also sCB (closing modal)
};

export const deleteClient = (id, sCB) => {
  return myThunk.delete(api.CLIENTS + id, deleteClientAC, { sCB });
  // fCB dealt with in modal from returned errors, also part of sCB (closing modal)
};

export const deleteTest = (clientId, test, sCB) => {
  const action = deleteTestAC(clientId, test);
  return myThunk.delete(api.RESULTS + test.id, action, { sCB });
  // fCB dealt with in modal from returned errors, also part of sCB (closing modal)
};

export const toggleTestsSeen = (clientId, unseenTestIds, testCode) => {
  const action = toggleTestsSeenAC(clientId, unseenTestIds, testCode);
  return myThunk.patch(api.RESULTS, { clientId, unseenTestIds }, action);
  // TODO: fCB???
};

const clientDateConverter = createDateConverter('created', 'lastTestTime');
const testDateConverter = createDateConverter('created');

const injestClientsAndTests = (newState, ...clients) => {
  for (const c of clients) {
    const noTests = !c.lastTestTime;
    clientDateConverter(c);
    // Overwrite the date `null` (`clientDateConverter` turns `null` into (1/1/1970)).
    // Use `Infinity` so clients w/o tests are at top when sorted by last test date (display shows N/A in ClientRow)
    if (noTests) c.lastTestTime = Infinity;
    for (const type in c.tests) {
      for (const test in c.tests[type]) testDateConverter(c.tests[type][test]);
    }
    newState[c.id] = c;
  }
  return newState;
};

// Reducer
const clientReducer = (state = {}, action) => {
  // const newState = JSON.parse(JSON.stringify(state));
  // decided vvv vs. ^^^ after check various discussions on speed & reliabiliy (^^^ can't do dates)
  const newState = _.cloneDeep(state);
  // NOTE: it's also faster that built in `const newState = structuredClone(state);`

  switch (action.type) {
    case SET_ALL:
      return injestClientsAndTests(newState, ...action.clients);

    case CREATE:
    case SET_ONE:
    case UPDATE:
    case RESET_LINKS:
      return injestClientsAndTests(newState, action.client);

    case TOGGLE_TESTS_SEEN: {
      // update tests to seen one at a time
      const tests = newState[action.clientId]['tests'];
      for (let id of action.testIdsToToggle) {
        tests[action.testCode][id].userSeen = true;
      }
      // remove testCode from unseenTests
      _.pull(newState[action.clientId].unseenTests, action.testCode);
      return newState;
    }

    case REMOVE_TEST: {
      const tests = newState[action.clientId]['tests'];
      delete tests[action.test.testCode][action.test.id];
      if (_.isEmpty(tests[action.test.testCode])) {
        delete tests[action.test.testCode];
      }
      return newState;
    }

    case REMOVE_CLIENT:
      delete newState[action.clientId];
      return newState;

    default:
      return newState;
  }
};

export default clientReducer;
